import styled, { css } from 'styled-components';
import { width, height, space } from 'styled-system';

const Hr = styled.div`
  ${width}
  ${height}
  ${space}
  ${({ theme }) => css`
    background-color: ${theme.borderColor};
  `}
`;

export default Hr;
